import React from 'react';

const FailIcon = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
  >
    <g
      fill="none"
      fillRule="evenodd"
      stroke="#EE7904"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
      transform="translate(1 1)"
    >
      <circle cx="10" cy="10" r="10"/>
      <path d="M13 7l-6 6M7 7l6 6"/>
    </g>
  </svg>
);

export default FailIcon;

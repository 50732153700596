import React from 'react'
// import Popup from './components/Popup'


export const LoadingScreen = () => (
  <h2 className='LoadingScreen'>Loading Landemic. Refresh if it doesn't load immediately.</h2>
  // <Popup>
  //   <div className='LoadBar' />
  //   <h2 className='LoadingScreen'>Loading Landemic Refresh if it doesn’t load immediately</h2>
  // </Popup>
)
